import React from "react"
import {
  Section,
  SectionHero,
  Container,
  ImageGrid,
} from "../components/Components"

const Intro = ({ id, sections }) => (
  <article id={id}>
    <SectionHero
      title="System"
      text="YOOX is one of the original innovators of online shopping. We wanted to build a system that could both serve business and engage our audience. Something that had to marry the brand with the products. It had to be variable yet systematic and consistent. A stream that could unify every touchpoint and let the brand be always recognizable. It had to be visually engaging, adapt to any format and hold up to interpretation by stakeholders around the globe."
    />
    <Section>
      <Container>
        <ImageGrid items={sections} />
      </Container>
    </Section>
  </article>
)

export default Intro
