import React, { Component } from "react"
import { navigate } from "@reach/router"

import classNames from "classnames"

import styles from "./Navigator.module.scss"

class Navigator extends Component {
  constructor(props) {
    super(props)
    this.state = { show: false, currentSection: null }
  }

  showModal = event => {
    event.preventDefault()
    this.setState({ show: true })
    // document.querySelector("html").classList.add("is-clipped")
  }

  hideModal = () => {
    this.setState({ show: false })
    // document.querySelector("html").classList.remove("is-clipped")
  }

  goLink = event => {
    event.preventDefault()
    this.setState({ show: false })
    // document.querySelector("html").classList.remove("is-clipped")
    navigate(event.target.href)
  }

  render() {
    const Modal = ({ handleClose, handleLink, show, menu }) => {
      const showHideClassName = classNames([
        "modal",
        styles.modal,
        show ? "is-active" : null,
      ])

      return (
        <div className={showHideClassName}>
          <div className={styles.background}>
            <div className={styles.container}>
              <div className={styles.content}>
                <div className={styles.column} /> {/* Second column */}
                <div className={styles.column}>
                  <div className={styles.inside}>
                    <ul className={styles.menu}>
                      {menu.map((item, index) => (
                        <li key={index} className={styles.item}>
                          {/* eslint-disable-next-line*/}
                          <a
                            href={`#${item.id}`}
                            className={classNames(["link-label", styles.link])}
                            onClick={handleLink}
                          >
                            {item.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="modal-close is-large"
              aria-label="close"
              onClick={handleClose}
            />
          </div>
        </div>
      )
    }

    return (
      <div className={classNames(["navigator", styles.navigator])}>
        <nav className={styles.controls}>
          {/* eslint-disable-next-line*/}
          <a
            href="#"
            role="button"
            className={classNames([styles.burger])}
            aria-label="navigator"
            aria-expanded="false"
            onClick={this.showModal}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </nav>
        <Modal
          show={this.state.show}
          handleClose={this.hideModal}
          menu={this.props.menu}
          handleLink={this.goLink}
        />
      </div>
    )
  }
}

export default Navigator
