import React, { Component } from "react"
import classNames from "classnames"

class Image extends Component {
  constructor(props) {
    super(props)
    this.state = { show: false }
  }

  showLightBox = event => {
    event.preventDefault()
    this.setState({ show: true })
    // document.querySelector("html").classList.add("is-clipped")
  }

  hideLightBox = () => {
    this.setState({ show: false })
    // document.querySelector("html").classList.remove("is-clipped")
  }

  render() {
    const Modal = ({ handleClose, show, src, alt }) => {
      const showHideClassName = classNames(["modal", show ? "is-active" : null])

      return (
        <div className={showHideClassName}>
          <div className="modal-background" />
          <div className="modal-content">
            <figure className="image">
              <img src={src} alt={alt} />
            </figure>
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={handleClose}
          />
        </div>
      )
    }

    const { ratio, src, alt, classes } = this.props

    const ratios = {
      none: "",
      square: "is-square",
      fourBytree: "is-4by3",
      treeByTwo: "is-3by2",
    }

    let figureClasses = classNames([
      "image",
      ratio in ratios ? ratios[ratio] : ratios["fourBytree"],
      ...classes,
    ])

    return (
      <>
        <figure className={figureClasses}>
          <img src={src} alt={alt} onClick={this.showLightBox} />
        </figure>
        <Modal
          show={this.state.show}
          handleClose={this.hideLightBox}
          src={src}
          alt={alt}
        />
      </>
    )
  }
}

export default Image
