import React from "react"
import {
  Section,
  SectionHero,
  Container,
  Image,
  Heading,
  Video,
} from "../components/Components"

const Iconography = ({ id }) => (
  <article id={id}>
    <SectionHero
      size="large"
      classes={["is-light"]}
      title="Iconography"
      text="It’s not a set of emotions. It’s a new proprietary communication tool to share info in an entertaining way."
    />
    <Section>
      <Container>
        <div className="content">
          <Heading tag="h3" type="subtitle" text="Icons" />
          {/* <p>
            Sed blandit nisl nisl, quis bibendum felis facilisis quis. Praesent
            viverra ante ut metus posuere pulvinar
          </p> */}
          <Image
            src={require("../images/brand-book/iconography/icons.png")}
            alt="Icons"
            ratio="none"
          />
          <Video
            src={require("../images/brand-book/iconography/icons.mp4")}
            autoplay
          />
        </div>
      </Container>
    </Section>
  </article>
)

export default Iconography
