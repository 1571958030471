import React from "react"
import {
  Section,
  SectionHero,
  Container,
  Image,
  Heading,
  Hero,
} from "../components/Components"

const Photography = ({ id }) => (
  <article id={id}>
    <SectionHero
      size="large"
      classes={["is-light"]}
      title="Art Direction"
      text="The only way to match fashion, art and design with life."
    />
    <Section>
      <Container>
        <div className="content">
          <Heading tag="h3" type="subtitle" text="Photo" />
          {/* <p>
            Sed blandit nisl nisl, quis bibendum felis facilisis quis. Praesent
            viverra ante ut metus posuere pulvinar
          </p> */}
        </div>
        <div className="columns is-multiline">
          <div className="column is-half">
            <Image
              src={require("../images/brand-book/photography/experience.jpg")}
              ratio="none"
              alt="Experience"
            />
            <p>40% Experience</p>
          </div>
          <div className="column is-half">
            <Image
              src={require("../images/brand-book/photography/device.jpg")}
              ratio="none"
              alt="Device"
            />
            <p>40% Digital Shopping Experience</p>
          </div>
          <div className="column is-half">
            <Image
              src={require("../images/brand-book/photography/hero.jpg")}
              ratio="none"
              alt="Hero"
            />
            <p>10% Faces</p>
          </div>
          <div className="column is-half">
            <Image
              src={require("../images/brand-book/photography/texture.jpg")}
              ratio="none"
              alt="Texture"
            />
            <p>10% Textures</p>
          </div>
        </div>
      </Container>
    </Section>
    <Hero
      size="fullheight"
      classes={["hero-background"]}
      styleInline={{
        backgroundImage: `url('${require("../images/brand-book/photography/art-direction.jpeg")}')`,
      }}
    />
  </article>
)

export default Photography
