import React from "react"

const Footer = () => (
  <footer className="footer">
    <div className="content">
      <p>©KleinRusso 2019</p>
    </div>
  </footer>
)

export default Footer
