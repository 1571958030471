import React from "react"

import Container from "../components/Container"
import Heading from "../components/Heading"
import Hero from "../components/Hero"
import HeroBody from "../components/HeroBody"

export default ({ size, title, text, classes }) => (
  <Hero size={size ? size : "medium"} classes={classes}>
    <HeroBody>
      <Container>
        <Heading tag="h1" text={title} />
        <p className="is-size-4">{text}</p>
      </Container>
    </HeroBody>
  </Hero>
)
