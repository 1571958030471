import React from "react"
import classNames from "classnames"

const Hero = ({ classes, size, hasNavbar, styleInline, children }) => {
  const sizes = {
    medium: "is-medium",
    large: "is-large",
    fullheight: "is-fullheight",
  }

  const style = classNames([
    "hero",
    size in sizes
      ? Boolean((size === "fullheight") & Boolean(hasNavbar))
        ? "is-fullheight-with-navbar"
        : sizes[size]
      : null,
    ...classes,
  ])

  const inline = styleInline ? styleInline : null

  return (
    <section className={style} style={inline}>
      {children}
    </section>
  )
}

export default Hero
