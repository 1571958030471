import React, { Component } from "react"
import { navigate } from "@reach/router"

import { Image } from "./Components"

class ImageGrid extends Component {
  handleItem = event => {
    event.preventDefault()
    navigate(event.currentTarget.href)
  }

  render() {
    const ImageItem = ({ src, title }) => (
      <Image src={src} ratio="square" alt={title} />
    )

    const VideoItem = ({ index, src }) => (
      <video key={index} src={src} playsInline loop muted autoPlay />
    )

    return (
      <div className="columns is-multiline">
        {this.props.items.map((item, index) => (
          <div key={index} className="column is-4">
            <a href={`#${item.id}`} onClick={this.handleItem}>
              {item.type === "video" ? (
                <VideoItem src={item.src} />
              ) : (
                <ImageItem src={item.src} title={item.title} />
              )}
              <span>{item.title}</span>
            </a>
          </div>
        ))}
      </div>
    )
  }
}

export default ImageGrid
