import React from "react"
import classNames from "classnames"

const Heading = ({ tag, type, classes, size, text }) => {
  const types = { title: "title", subtitle: "subtitle" }
  const sizes = {
    "1": "is-size-1",
    "2": "is-size-2",
    "3": "is-size-3",
    "4": "is-size-4",
    "5": "is-size-5",
    "6": "is-size-6",
  }
  const TagName = `${tag}`
  const headingClasses = classNames([
    type & (Object.keys(types) === type) ? types[type] : "title",
    size & (size in sizes) ? sizes[size] : null,
    ...classes,
  ])
  return <TagName className={headingClasses}>{text}</TagName>
}

export default Heading
