import React from "react"
import {
  Section,
  SectionHero,
  Container,
  Image,
  Heading,
  Video,
} from "../components/Components"

const Payoff = ({ id }) => (
  <article id={id}>
    <SectionHero
      size="large"
      classes={["is-light"]}
      title="Payoff"
      text="The tag-line is born from the strong legacy of the brand. It aims to drive YOOX communication to the future."
    />
    <Section>
      <Container>
        <div className="content">
          <Heading tag="h3" type="subtitle" text="Legacy" />
          <Video
            src={require("../images/brand-book/payoff/payoff-animation.mp4")}
          />
          <Heading tag="h3" type="subtitle" text="In use" />
          <Video
            src={require("../images/brand-book/payoff/payoff-words.mp4")}
          />
        </div>
      </Container>
    </Section>
  </article>
)

export default Payoff
