import React from "react"

export default ({ src, autoplay }) => (
  <video
    src={src}
    playsInline
    controls={!autoplay}
    loop={autoplay}
    autoPlay={autoplay}
    muted={autoplay}
  />
)
