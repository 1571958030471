import React from "react"

import "../styles/style.scss"
import { Layout, Navigator, PageHero } from "../components/Components"

import sections, {
  Intro,
  Payoff,
  Composition,
  Typography,
  Color,
  Iconography,
  Motion,
  Photography,
  Illustration,
  Tone,
} from "../brand-book/sections"
import { navigation } from "../common/navigation"

const BrandBookPage = () => {
  return (
    <Layout>
      <PageHero
        title="YOOX Brand Book"
        menu={navigation}
        classes={["has-background-black", "is-dark"]}
        // styles={{
        //   backgroundImage: `url("${require("../images/brand-book/bg-brand-book.jpg")}")`,
        // }}
      />
      <Navigator menu={sections} />
      <Intro id="intro" sections={sections} />
      <Payoff id="payoff" />
      <Composition id="composition" />
      <Typography id="typography" />
      <Color id="color" />
      <Iconography id="iconography" />
      <Motion id="motion" />
      <Photography id="photography" />
      <Illustration id="illustration" />
      <Tone id="tone" />
    </Layout>
  )
}

export default BrandBookPage
