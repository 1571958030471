import React, { Component } from "react"
import classNames from "classnames"
import styles from "./videoPlay.module.scss"

class VideoPlay extends Component {
  constructor(props) {
    super(props)
    this.state = { playVideo: false }
    this.handlePlay = this.handlePlay.bind(this)
  }

  handlePlay = event => {
    event.preventDefault()
    this.setState(prevState => ({
      playVideo: !prevState.playVideo,
    }))
    this.refs.vidRef.play()
  }

  render() {
    return (
      <div className={styles.videoContainer}>
         playsInline ref="vidRef" src={this.props.src} playsInline controls />
        <button
          className={classNames([
            "button",
            "is-text",
            "link-label",
            styles.play,
          ])}
          onClick={this.handlePlay}
        >
          Play
        </button>
      </div>
    )
  }
}

export default VideoPlay
