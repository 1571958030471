import Intro from "../brand-book/intro"
import Payoff from "../brand-book/payoff"
import Composition from "../brand-book/composition"
import Typography from "../brand-book/typography"
import Color from "../brand-book/color"
import Iconography from "../brand-book/iconography"
import Motion from "../brand-book/motion"
import Photography from "../brand-book/photography"
import Illustration from "../brand-book/illustration"
import Tone from "../brand-book/tone"

const sections = [
  {
    id: "payoff",
    src: require("../images/brand-book/intro/payoff.png"),
    type: "image",
    title: "Logo",
  },
  {
    id: "composition",
    src: require("../images/brand-book/intro/composition.png"),
    type: "image",
    title: "Composition",
  },
  {
    id: "typography",
    src: require("../images/brand-book/intro/typography.mp4"),
    type: "video",
    title: "Typography",
  },
  {
    id: "color",
    src: require("../images/brand-book/intro/color.mp4"),
    type: "video",
    title: "Color",
  },
  {
    id: "iconography",
    src: require("../images/brand-book/intro/iconography.png"),
    type: "image",
    title: "Iconography",
  },
  {
    id: "motion",
    src: require("../images/brand-book/intro/motion.png"),
    type: "image",
    title: "Motion",
  },
  {
    id: "photography",
    src: require("../images/brand-book/intro/photography.png"),
    type: "image",
    title: "Art Direction",
  },
  {
    id: "illustration",
    src: require("../images/brand-book/intro/illustration.png"),
    type: "image",
    title: "Illustration",
  },
  {
    id: "tone",
    src: require("../images/brand-book/intro/tone.mp4"),
    type: "video",
    title: "Tone of Voice",
  },
]

export default sections

export {
  Intro,
  Payoff,
  Composition,
  Typography,
  Color,
  Iconography,
  Motion,
  Photography,
  Illustration,
  Tone,
}
