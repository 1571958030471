import React from "react"
import {
  Section,
  SectionHero,
  Container,
  Image,
  Heading,
} from "../components/Components"

const Tone = ({ id }) => (
  <article id={id}>
    <SectionHero
      size="large"
      classes={["is-light"]}
      title="Tone Of Voice"
      text="When you find the words you need to know how to use them."
    />
    <Section>
      <Container>
        <div className="content">
          <Image
            src={require("../images/brand-book/tone/concerto.jpg")}
            ratio="treeByTwo"
            ratio="none"
          />
          <Image
            src={require("../images/brand-book/tone/got.jpg")}
            ratio="treeByTwo"
            ratio="none"
          />
          <Image
            src={require("../images/brand-book/tone/shibuya.jpg")}
            ratio="treeByTwo"
            ratio="none"
          />
        </div>
      </Container>
    </Section>
  </article>
)

export default Tone
