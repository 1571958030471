import React from "react"
import {
  Section,
  SectionHero,
  Content,
  Container,
  Heading,
  Video,
} from "../components/Components"

const Motion = ({ id }) => (
  <article id={id}>
    <SectionHero
      size="large"
      classes={["is-light"]}
      title="Motion"
      text="Always delighting eyes and hearts with videos, photos, images, gifts and more."
    />
    <Section>
      <Container>
        <Content>
          <Heading tag="h3" type="subtitle" text="Transitions" />
          {/* <p>
            Sed blandit nisl nisl, quis bibendum felis facilisis quis. Praesent
            viverra ante ut metus posuere pulvinar
          </p> */}
        </Content>
        <div className="columns">
          <div className="column is-one-third">
            <Video
              src={require("../images/brand-book/motion/motion-1.mp4")}
              autoplay
            />
          </div>
          <div className="column is-one-third">
            <Video
              src={require("../images/brand-book/motion/motion-2.mp4")}
              autoplay
            />
          </div>
          <div className="column is-one-third">
            <Video
              src={require("../images/brand-book/motion/motion-3.mp4")}
              autoplay
            />
          </div>
        </div>
        <Content>
          <Heading tag="h3" type="subtitle" text="In use" />
          <Video
            src={require("../images/brand-book/motion/transition.mp4")}
            autoplay
          />
        </Content>
      </Container>
    </Section>
  </article>
)

export default Motion
