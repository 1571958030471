import React from "react"

import "../styles/style.scss"
import Helmet from "./Helmet"
import { Footer } from "./Components"

const Layout = ({ children }) => (
  <div>
    <Helmet />
    {children}
    <Footer />
  </div>
)

export default Layout
