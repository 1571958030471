import React from "react"
import {
  Hero,
  HeroHead,
  HeroBody,
  Navbar,
  Container,
  Heading,
} from "../components/Components"

const PageHero = ({ size, title, text, menu, classes, styles }) => (
  <Hero
    size={size ? size : "fullheight"}
    hasNavbar
    classes={["is-light", ...classes]}
    styleInline={styles}
  >
    {menu && (
      <HeroHead>
        <Navbar end={menu} />
      </HeroHead>
    )}
    <HeroBody>
      <Container>
        <Heading tag="h1" text={title} />
        {text && <p className="is-size-4">{text}</p>}
      </Container>
    </HeroBody>
  </Hero>
)

export default PageHero
