import React from "react"

import "../styles/style.scss"
import Helmet from "./Helmet"

const LayoutHome = ({ children }) => (
  <div>
    <Helmet />
    {children}
  </div>
)

export default LayoutHome
