import React, { Component } from "react"
import { Link } from "gatsby"

import classNames from "classnames"

class Navbar extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <nav className="navbar">
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              KleinRusso x YOOX
            </Link>
            {/* eslint-disable-next-line*/}
            <a
              href="#"
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbar-top"
              onClick={this.toggle}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>
          <div
            id="navbar-top"
            className={classNames([
              "navbar-menu",
              this.state.isOpen && `is-active`,
            ])}
          >
            <div className="navbar-end">
              {this.props.end && this.props.end.links
                ? this.props.end.links.map((item, index) => (
                    <Link key={index} className="navbar-item" to={item.url}>
                      {item.label}
                    </Link>
                  ))
                : null}
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
