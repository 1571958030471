import React from "react"
import {
  Section,
  SectionHero,
  Container,
  Image,
  Heading,
  Content,
} from "../components/Components"

const Color = ({ id }) => (
  <article id={id}>
    <SectionHero
      size="large"
      classes={["is-light"]}
      title="Color"
      text="#000000 is the YOOX black. And it’s not alone."
    />
    <Section>
      <Container>
        <div className="columns">
          <div className="column is-half">
            <Heading tag="h3" type="subtitle" text="Primary" />
          </div>
          <div className="column is-half">
            <Heading tag="h3" type="subtitle" text="Secondary" />
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-fourth">
            <figure className="image is4by3">
              <img
                alt="Transparent"
                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                style={{ visibility: `hidden` }}
              />
              <div
                style={{
                  border: `2px solid #191919`,
                  position: `absolute`,
                  top: 0,
                  right: 0,
                  width: `100%`,
                  height: `100%`,
                  backgroundColor: `#ffffff`,
                }}
              />
            </figure>
            <p>White</p>
          </div>
          <div className="column is-one-fourth">
            <figure className="image is4by3">
              <img
                alt="Transparent"
                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                style={{ visibility: `hidden` }}
              />
              <div
                style={{
                  position: `absolute`,
                  top: 0,
                  right: 0,
                  width: `100%`,
                  height: `100%`,
                  backgroundColor: `#191919`,
                }}
              />
            </figure>
            <p>Black</p>
          </div>
          <div className="column is-one-fourth">
            <figure className="image is4by3">
              <img
                alt="Transparent"
                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                style={{ visibility: `hidden` }}
              />
              <div
                style={{
                  position: `absolute`,
                  top: 0,
                  right: 0,
                  width: `100%`,
                  height: `100%`,
                  background: `linear-gradient(90deg, #404040 0%, #404040 33.333333%, #7f7f7f 33.333333%, #7f7f7f 33.333333%, #7f7f7f 66.666666%, #bfbfbf  66.666666%, #bfbfbf  100%)`,
                }}
              />
            </figure>
            <p>Grey</p>
          </div>
          <div className="column is-one-fourth">
            <figure className="image is4by3">
              <img
                alt="Transparent"
                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                style={{ visibility: `hidden` }}
              />
              <div
                style={{
                  position: `absolute`,
                  top: 0,
                  right: 0,
                  width: `100%`,
                  height: `100%`,
                  background: `#f4df00`,
                }}
              />
            </figure>
            <p>Yellow</p>
          </div>
        </div>
        <Content>
          <Heading tag="h3" type="subtitle" text="Pattern" />
          <p>
            The soul of YOOX style: a mix of colors, iconography and typography.
          </p>
        </Content>
        <div className="columns">
          <div className="column is-half">
            <Image
              src={require("../images/brand-book/color/pattern1.jpg")}
              alt="Pattern 1"
            />
          </div>
          <div className="column is-half">
            <Image
              src={require("../images/brand-book/color/pattern2.jpg")}
              alt="Pattern 2"
            />
          </div>
        </div>
        <Content>
          <Image
            src={require("../images/brand-book/color/yoox-pattern.jpg")}
            alt="YOOX Pattern"
          />
        </Content>
        <div className="columns">
          <div className="column is-half">
            <Image
              src={require("../images/brand-book/color/pattern3.png")}
              alt="Pattern 3"
            />
          </div>
          <div className="column is-half">
            <Image
              src={require("../images/brand-book/color/pattern4.png")}
              alt="Pattern 4"
            />
          </div>
        </div>
      </Container>
    </Section>
  </article>
)

export default Color
