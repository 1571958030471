import React from "react"
import {
  Section,
  SectionHero,
  Heading,
  Container,
  Content,
  Image,
  Hero,
  HeroBody,
  Video,
} from "../components/Components"

const Typography = ({ id }) => (
  <article id={id}>
    <SectionHero
      size="large"
      classes={["is-light"]}
      title="Typography"
      text="The sound of YOOX voice is a mix of style."
    />
    <Section>
      <Container>
        <Content>
          <Heading tag="h3" type="subtitle" text="Montserrat" />
          <Video
            src={require("../images/brand-book/typography/montserrat.mp4")}
            autoplay
          />
          <Heading tag="h3" type="subtitle" text="Guidelines" />
          <Image
            src={require("../images/brand-book/typography/typography-guide.png")}
            alt="Typography Guideline"
            ratio="none"
          />
        </Content>
      </Container>
    </Section>
  </article>
)

export default Typography
