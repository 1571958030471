import React from "react"
import {
  Section,
  SectionHero,
  Container,
  Content,
  Heading,
  Hero,
  Video,
} from "../components/Components"

const Composition = ({ id }) => (
  <article id={id}>
    <SectionHero
      size="large"
      classes={["is-light"]}
      title="Composition"
      text="The staple of brand design starts from the logo and evolves out of the box."
    />
    <Section>
      <Container>
        <div className="columns">
          <div className="column is-half">
            <div className="content">
              <Heading tag="h3" type="subtitle" text="YOOX Label" />
              <Video
                src={require("../images/brand-book/composition/label.mp4")}
              />
              {/* <p>
                Sed blandit nisl nisl, quis bibendum felis facilisis quis.
                Praesent viverra ante ut metus posuere pulvinar
              </p> */}
            </div>
          </div>
          <div className="column is-half">
            <div className="content">
              <Heading tag="h3" type="subtitle" text="YOOX Dynamic Label" />
              <Video
                src={require("../images/brand-book/composition/grid.mp4")}
              />
              {/* <p>
                Sed blandit nisl nisl, quis bibendum felis facilisis quis.
                Praesent viverra ante ut metus posuere pulvinar
              </p> */}
            </div>
          </div>
        </div>
        <Content>
          <Heading tag="h3" type="subtitle" text="YOOX Dynamic Logo" />
          <Video
            src={require("../images/brand-book/composition/dynamic-logo.mp4")}
          />
          <Heading tag="h3" type="subtitle" text="YOOX Dynamic Format" />
        </Content>
        <div className="columns">
          <div className="column is-one-third">
            <div className="content">
              <Video
                src={require("../images/brand-book/composition/yoox-dynamic-format-1.mp4")}
                autoplay
              />
              <p>Logo</p>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="content">
              <Video
                src={require("../images/brand-book/composition/yoox-dynamic-format-2.mp4")}
                autoplay
              />
              <p>Horizontal</p>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="content">
              <Video
                src={require("../images/brand-book/composition/yoox-dynamic-format-3.mp4")}
                autoplay
              />
              <p>Vertical</p>
            </div>
          </div>
        </div>
      </Container>
    </Section>
    <Hero
      size="fullheight"
      classes={["hero-background"]}
      styleInline={{
        backgroundImage: `url('${require("../images/brand-book/composition/domination-ny.jpg")}')`,
      }}
    />
  </article>
)

export default Composition
