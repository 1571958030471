import React from "react"
import {
  Section,
  SectionHero,
  Container,
  Image,
  Heading,
  Hero,
  Video,
} from "../components/Components"

const Illustration = ({ id }) => (
  <article id={id}>
    <SectionHero
      size="large"
      classes={["is-light"]}
      title="Illustration"
      text="Just one principle: start from the white and go on simply."
    />
    <Section>
      <Container>
        <div className="content">
          <Heading tag="h3" type="subtitle" text="In use" />
          {/* <p>
            Sed blandit nisl nisl, quis bibendum felis facilisis quis. Praesent
            viverra ante ut metus posuere pulvinar
          </p> */}
        </div>
        <div className="columns">
          <div className="column is-half">
            <Image
              src={require("../images/brand-book/illustration/netiquette.jpg")}
              alt="Netiquette"
              ratio="none"
            />
          </div>
          <div className="column is-half">
            <Image
              src={require("../images/brand-book/illustration/app-download.jpg")}
              alt="App Download"
              ratio="none"
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <p>Netiquette</p>
          </div>
          <div className="column is-half">
            <p>App Download</p>
          </div>
        </div>
        <div className="content">
          <Heading tag="h3" type="subtitle" text="Animation" />
          <Video
            src={require("../images/brand-book/illustration/illustration.mp4")}
            autoplay
          />
        </div>
      </Container>
    </Section>
  </article>
)

export default Illustration
